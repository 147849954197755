<template>
    <h1 class="form-title">
        {{ form.survey_name }}
        <small v-if="user && user.id && launchpad"><a class="pointer" @click="toggleLaunchPad()">Open Launchpad</a></small>
    </h1>
    <div class="info_wpr">
        <div class="border_item">
            <h5>Name</h5>
            <p class="small">{{ formDetails.respondent_info && formDetails.respondent_info.name ? formDetails.respondent_info.name : '—' }}</p>
        </div>
        <div class="border_item">
            <h5>Email Address</h5>
            <p class="small">{{ formDetails.respondent_info && formDetails.respondent_info.email ? formDetails.respondent_info.email : '—' }}</p>
        </div>
        <div class="border_item">
            <h5>Phone</h5>
            <p class="small">{{ formDetails.respondent_info && formDetails.respondent_info.number ? formDetails.respondent_info.number : '—' }}</p>
        </div>
        <div class="border_item">
            <h5>Date of Birth</h5>
            <p class="small">{{ formDetails.respondent_info && formDetails.respondent_info.birthdate ? formDetails.respondent_info.birthdate : '—' }}</p>
        </div>
        <div class="border_item">
            <h5>Address</h5>
            <p class="small">{{ formDetails.respondent_info && formDetails.respondent_info.full_address ? formDetails.respondent_info.full_address : '—' }}</p>
        </div>

        <template v-if="formDetails.custom_fields && formDetails.custom_fields.length && Object.values(formDetails.custom_fields_answers).length">
            <template v-for="(field, f) of formDetails.custom_fields" :key="f">
                <div class="border_item" v-if="formDetails.custom_fields_answers[field.id] != undefined">
                    <h5>{{ field.name }}</h5>
                    <p class="small">{{ formDetails.custom_fields_answers[field.id] }}</p>
                </div>
            </template>
        </template>
    </div>
    <div v-if="formDetails.survey_questions && formDetails.survey_questions.length" class="form_details">
        <hr />
        <div v-for="(question, q) of formDetails.survey_questions" :key="q" class="form_wrapper">
            <template v-if="question.input_type_id == 7">
                <div class="flex">
                    <label>{{ ++q }}. &nbsp </label>
                    <label class="input_label">{{ question.headline }}</label>
                </div>
                <p class="ml-3 agreement redactor-styles redactor-in" v-html="question.parse_question"></p>
                <label :for="`agreement-${question.id}`" class="checkbox agree_box">
                    <input type="checkbox" :id="`agreement-${question.id}`" :true-value="1" :false-value="0" hidden v-model="question.answer" disabled>
                    <span class="mr-2" :style="`border:1px solid ${form.accent_color ? form.accent_color : '#2c3e50'}`"><i class="fas fa-check" :style="`color:${form.accent_color ? form.accent_color : '#2c3e50'}`"></i></span>
                    <p class="info m-0">I Agree.</p>
                </label>
                <div class="preview-content">
                    <table class="initial_item" v-if="question.initials && question.initials.initial">
                        <tr class="p-0">
                            <td class="p-0">
                                <p class="initial_text">{{ question.initials.initial }}</p>
                            </td>
                            <td class="p-0">
                                <p class="initial_time p-0">You Initialed this at {{ question.initials.hour }} on {{ question.initials.day }}</p>
                                <p class="initial_ip p-0">Recorded IP : {{ question.initials.ip_address }}</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </template>
            <div class="flex" v-else>
                <label>{{ ++q }}. &nbsp </label>
                <label class="input_label redactor-styles redactor-in" v-html="question.parse_question"></label>
            </div>
            <div class="ml-3"><small>{{ question.sub_text }}</small></div>
            <div class="field_wpr preview-content answer_item" v-if="question.input_type_id != 7">
                <div v-if="question.input_type_id == 1 || question.input_type_id == 4">
                    <div class="question_option" v-for="(option, o) in question.options" :key="o">
                        <div class="question-oneans">
                            <label :for="`radio-${question.id}-${o}`" class="radio_opt">
                                <input type="radio" :id="`radio-${question.id}-${o}`" :name="`option-${question.id}`" :checked="question.answer && question.answer.length && option.option == question.answer[0]" disabled>
                                <div class="radio_box" :class="{'active' : question.answer && question.answer.length && option.option == question.answer[0]}" :style="`border:1px solid ${form.accent_color ? form.accent_color : '#2c3e50'}`">
                                    <span class="dot" :style="`background:${form.accent_color ? form.accent_color : '#2c3e50'}`"></span>
                                </div>
                                <p>{{ option.option }}</p>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-else-if="question.input_type_id == 2">
                    <div class="question-options" v-for="(option, o) in question.options" :key="o">
                        <label class="checkbox" :for="`checkbox-${question.id}-${o}`">
                            <input type="checkbox" :id="`checkbox-${question.id}-${o}`" :name="`option-${question.id}`" :checked="question.answer && question.answer.length && question['answer'].includes(option.option)" disabled hidden>
                            <span class="mr-2" :style="`border:1px solid ${form.accent_color ? form.accent_color : '#2c3e50'}`"><i class="fas fa-check" :style="`color:${form.accent_color ? form.accent_color : '#2c3e50'}`"></i></span>
                            <p>{{ option.option }}</p>
                        </label>
                    </div>
                </div>
                <div class="setting_wpr"  v-else-if="question.input_type_id == 3">
                    <div class="form_grp py-2">
                        <div class="group_item">
                            <div class="field_wpr m-0">
                                <textarea cols="30" rows="10" :style="`height: ${( question.answer ? (question.answer.length / 3) : 50 )}px;`" :value="question.answer" readonly></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="setting_wpr"  v-else-if="question.input_type_id == 5">
                    <div class="form_grp py-2">
                        <div class="group_item">
                            <div class="field_wpr m-0">
                                <input type="text" :name="`answer-${question.id}`" :value="question.answer" readonly/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-else-if="question.input_type_id == 6">
                    <div v-html="question.answer"></div>
                </div>
                <!-- <div class="setting_wpr py-2 m-0"  v-else-if="question.input_type_id == 7">
                    <input type="hidden" :name="`answer-${question.id}`" />
                </div> -->
                <table class="initial_item" v-if="question.initials && question.initials.initial">
                    <tr class="p-0">
                        <td class="p-0">
                            <p class="initial_text">{{ question.initials.initial }}</p>
                        </td>
                        <td class="p-0">
                            <p class="initial_time p-0">You Initialed this at {{ question.initials.hour }} on {{ question.initials.day }}</p>
                            <p class="initial_ip p-0">Recorded IP : {{ question.initials.ip_address }}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div v-if="form && form.is_signature" class="mb-0 form_details">
        <section class="p-0 optin-section-border-top">
            <div class="custom_content">
                <i class="far fa-check-square"></i>
                <div v-html="form.parse_signature"></div>
            </div>
            <div class="signature" v-if="formDetails.signatures">
                <span style="font-family: 'Caveat', cursive;font-size: 40px">{{ formDetails.signatures.fullname }}</span>
                <p style="margin-top:0px; float:right; font-family: 'Caveat', cursive;font-size: 40px">{{ formDetails.signatures.ddate }}</p>
            </div>
            <div class="custom_content mt-3" v-if="formDetails.signatures">
                <i class="far fa-check-square"></i>
                <label class="legal_sign" for="customCheck344141">This is my legally binding signature.</label>
            </div>
            <div class="privacy_info" :style="{ background: form.ip_bgcolor ? form.ip_bgcolor : '#eaf7fc', color: form.ip_textcolor ? form.ip_textcolor : '#2c3e50' }">
                <i class="fas fa-shield-alt" :style="{ color: form.ip_textcolor ? form.ip_textcolor : '#2c3e50' }"></i>
                <p v-if="formDetails.signatures" :style="`color: ${form.ip_textcolor ? form.ip_textcolor : '#2c3e50'} !important;`">Your IP address {{ formDetails.signatures.ip_address }} has been recorded at {{ formDetails.signatures.hour}} on <br> {{ formDetails.signatures.day }}, {{ formDetails.signatures.ddate }}. This signature is legally binding in a court of law </p>
            </div>
        </section>
    </div>

    <launch-pad v-model="lauchPad" :contact="contact" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState } from 'vuex'

    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))

    export default {
        name: 'Form Detail Content',

        data () {
            return {
                form: this.formDetails.survey_info,
                lauchPad: false,
            };
        },

        props: {
            formDetails: Object,

            contact: {
                type: Object,
                default: () => {}
            },

            launchpad: {
                type: Boolean,
                default: true
            },
        },

        watch: {
            lauchPad (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
        }),

        components: {
            LaunchPad,
        },

        mounted () {
            const vm = this;

            vm.form = vm.formDetails.survey_info;
        },

        methods: {
            toggleLaunchPad () {
                const vm = this;

                vm.lauchPad = true;
            },
        }
    }
</script>

<style scoped>
    .info_wpr {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 30px -10px;
        padding: 0;
        border: 0;
        position: relative;
    }

    .info_wpr .border_item {
        flex: 1 0 50%;
        padding: 15px 10px;
    }

    .info_wpr .border_item h5 {
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
    }

    .info_wpr .border_item p {
        /* border: 1px solid #d9d9d9; */
        padding: 0;
        font-size: 16px;
        line-height: 32px;
        color: #5a5a5a;
    }

    .form_details {
        padding: 0;
        border: 0;
        border-radius: 0;
        position: relative;
        margin-bottom: 45px;
    }

    /* .form_details:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: var(--editbar-color, #f2a31d);
        border-radius: 6px 6px 0 0;
    } */

    .form_details .form_wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 7px;
        border: 0;
        background: #f9f9f9;
        padding: 20px 20px 10px 20px;
        border-radius: 6px;
        margin-bottom: 25px;
    }
    .form_details .form_wrapper .field_wpr{
        background: transparent;
    }

    .form_details :deep(label), .form_details :deep(label p) {
        font-size: 15px;
        line-height: 22px;
        color: #121525 !important;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .question_option {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 500;
        margin-bottom: 14px;
    }

    .question_option i {
        margin-right: 5px;
    }

    @keyframes smoothMove  {

        from  {transform: scale(0);}

        to  {transform: scale(1);}
    }

    .privacy_info {
        display: flex;
        padding: 20px 25px;
        border: 1px solid #bdeafa;
        background: rgba(149, 215, 239, 0.2);
        border-radius: 5px;
        align-items: flex-start;
        margin: 30px 0 20px 0;
    }

    .privacy_info i {
        font-size: 34px;
        color: #f2a31d;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px;
    }

    .signature_fld {
        display: flex;
        justify-content: space-between;
        font-family: Caveat,cursive;
        margin: 0 0 20px;
        padding: 10px 0;
        font-size: 20px;
        line-height: 25px;
        border-bottom: 1px solid #d9d9d9;
    }

    .form_details.legal_info label p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a !important;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px !important;
    }

    .edit_modal .edit_section .form_body .form_content p.small {
        font-size: 14px;
        line-height: 48px;
        color: #5a5a5a;
        font-weight: 400;
        margin: 0;
    }
    .question-oneans{
        display: flex;
        margin-bottom: 14px;
    }
    .field_wpr .question_option input[type=radio]{
        width: 14px;
        height: 14px;
        margin-top: 2px;
    }
    .form_details .preview-content .question_option p{
        padding: 0 0 0 12px;
        border: 0;
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
    }
    .form_details .preview-content .question-options label.checkbox{
        justify-content: flex-start;
    }
    .form_details .preview-content .question-options p{
        padding: 0;
        border: 0;
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a !important;
        font-weight: 400;
    }
    .field_wpr.answer_item :deep(a){
        font-size: 15px;
        line-height: 20px;
        text-decoration: none;
    }
    .field_wpr.answer_item textarea, .field_wpr.answer_item input[type=text]{
        min-height: 50px !important;
        padding: 15px;
        height: 100%;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
    }
    .form_details .custom_content{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .form_details .preview-content table {
        width: 100%;
    }

    .radio_box{
        width: 13px;
        height: 13px;
        flex-shrink: 0;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 3px;
    }
    .radio_box .dot{
        position: absolute;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .radio_box.active .dot{
        transform: scale(1);
    }

    :deep(.question-oneans .radio_opt) {
        display: flex;
        align-items: flex-start;
        margin: 0;
        cursor: pointer;
    }

    :deep(.question-oneans .radio_opt input) {
        display: none;
    }

    .agreement :deep(p){
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        margin-bottom: 20px;
    }

    label.checkbox.agree_box{
        margin-top: 20px;
        justify-content: flex-start;
    }
    label.checkbox.agree_box p{
        color: #121525;
    }

    .form-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 25px;
    }

    .form-title small a {
        font-size: 12px;
    }

    .initial_item tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .initial_item .initial_text {
        font-family: Caveat, cursive;
        font-size: 30px;
    }

    :deep(.redactor-styles){
        padding: 0;
    }

    :deep(.redactor-styles p) {
        font-size: 15px !important;
        line-height: 23px !important;
    }

    :deep(.redactor-styles p:empty) {
        min-height: 23px;
    }

    :deep(.redactor-styles.agreement p){
        font-size: 14px !important;
        line-height: 1.5 !important;
        font-weight: 400;
        color: #5a5a5a;
        min-height: 20px;
    }

    :deep(.redactor-styles.agreement p:empty){
        min-height: 20px;
    }

    :deep(.redactor-styles.agreement ul){
        margin-left: 20px;
    }

    :deep(.redactor-styles.agreement ul li){
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
        padding: 10px 0;
        list-style-type: disc;
    }
</style>
